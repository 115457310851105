@import '../../assets/scss/all.scss';
.TopBanner {
    align-items: center;
    display: flex;
    height: 100vh;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    width: 100%;
    z-index: 1;

    &::before {
        background: rgba(0, 0, 0, 0.6);
        content: "";
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
    }

    &_floating_bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
    }

    &_floating {
        animation: xFloating 20s linear infinite alternate;
        width: 30px;
        height: 30px;

        @include responsive(mobile) {  
            animation: xFloatingMobile 20s linear infinite alternate;
        }
        @for $i from 1 through 6 {
            .yFloat-#{$i} {
                animation: yFloating ($i + 5s) linear infinite alternate;
                animation-delay: ($i + 2s);
                max-width: 130px;
            }
        }
    }

    &_row {
        align-items: center;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(0, 0, 0, 0.3);
        display: flex;
        height: 100vh;
        width: 100%;
        z-index: 2;
    }
    &_content {
        position: relative;
        max-width: 1200px;
        margin-left: 10%;
        @include responsive(mobile) {  
            margin-left: 5%;
        }
        &-title {
            color: $third;
            font-size: 4.1rem;
            line-height: 4.1rem;
            text-shadow: 1px 1px 1px $fourth;
            
            @include responsive(mobile) {  
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }

        &-subtitle {
            color: $secondary;
            font-size: 4.1rem;
            line-height: 4.1rem;
            margin-top: 15px;
            text-shadow: 1px 1px 1px $fourth;

            @include responsive(mobile) {  
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }

        &-short {
            color: $third;
            display: block;
            font-size: 18px;
            margin-top: 15px;
            max-width: 370px;
            line-height: 27px;
        }
    }
}