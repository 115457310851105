@import '../../assets/scss/all.scss';

.portfolio-card {
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    flex-direction: column;
    transition: all .3s;
    border-radius: 53px;
    background: linear-gradient(145deg, #2d2e36, #26272d);
    background-size: cover;
    box-shadow:  15px 15px 35px #1a1b1f,
                -15px -15px 35px #3a3b45;
    color: white;
    display: flex;
    justify-content: center;
    min-height: 200px;
    height: 450px;
    position: relative;
    text-decoration: none;
    max-width: 400px;

    @include responsive(mobile) {    
        border-radius: 40px;
        margin: 15px;
    }

    .overlay {
        border-radius: 53px;
        background-color: $primary;
        height: 100%;
        transition: all .3s;
        position: absolute;
        opacity: 0.45;
        width: 100%;
        z-index: 1;

        @include responsive(mobile) {    
            border-radius: 40px;
        }
    }
    h3 {
        text-align: center;
        transition: all .3s;
        font-size: 22px;
        z-index: 2;
    }
    p {
        opacity: 0;
        transition: all .3s;
        padding: 16px;
        z-index: 2;
        position: relative;
        top: 15px;
    }
    .btn {
        background-color: $secondary;
        border: 0;
        opacity: 0;
        transition: all .6s;
        position: relative;
        top: 15px;
    }
    &:hover {
        cursor: pointer;
        backdrop-filter: blur(2px);
        background: #000000;
        background-position: center;
        background-size: cover;
        box-shadow: inset 15px 15px 35px #000000,
                    inset -15px -15px 35px #24252c;
        .overlay {
            opacity: 0.8;
        }
        h3 {
            font-size: 24px;
        }
        p, .btn {
            top: 0;
            opacity: 1;
        }
    }
}