$media: (
    sm: 640px,
    md: 768px,
    lg: 1024px,
    xl: 1280px,
    2xl: 1536px,
    3xl: 1920px,
);

@mixin responsive ($breakpoint) {
    @if $breakpoint==tablet {

        @media only screen and (min-width: map-get($media, sm)) and (max-width: map-get($media, lg)) {
            @content;
        }
    }

    @if $breakpoint==mobile {
        @media only screen and (max-width: map-get($media, sm)) {
            @content;
        }
    }
}