$font_path: '../fonts/';

@font-face {
    font-family: "Gantari";
    src: url($font_path+"Gantari-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Gantari-thin";
    src: url($font_path+"Gantari-Thin.ttf") format("truetype");
}

@font-face {
    font-family: "Gantari-bold";
    src: url($font_path+"Gantari-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Gantari-black";
    src: url($font_path+"Gantari-Black.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-thin";
    src: url($font_path+"Roboto-Thin.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-light";
    src: url($font_path+'Roboto-Light.ttf') format("truetype");
}

@font-face {
    font-family: "Roboto";
    src: url($font_path+"Roboto-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-bold";
    src: url($font_path+'Roboto-Bold.ttf') format("truetype");
}


h1, h2, h3{
    font-family: 'Gantari-bold' !important;
    text-shadow: 1px 1px 1px #000;
}

h4, h5, h6 {
    font-family: 'Roboto-light';
}

p, span {
    font-family: 'Gantari-thin';
}