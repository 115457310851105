// colors
$primary: #222831!important;
$primary-2: #393E46!important;
$primary-3: #212a39!important;
$secondary: #00ADB5!important;
$secondary-2: #13d7e6!important;
$secondary-3: #213f74!important;
$secondary-transparent: #3071e778!important;
$third: #EEEEEE!important;
$fourth: #000000!important;
$gray-blue: #A6B2EC!important;
$red: #FB2576!important;
$red-2: #D23369!important;
$footer: rgb(19, 24, 33);

$vue: #3eaf7c;
$jsvanilla: #f0dc1b;

// guide
//html - third 
//css - red
//php - secondary-2
//vuejs - vue
//reactjs - secondary
//salesforce - secondary-transparent
//Mysql - primary-2
//jsVanilla - jsvanilla