@import '../../assets/scss/all.scss';

.logos-list {
    background-color: $primary;
    padding: 1% 0;

    @include responsive(mobile) {
        margin: 15% 0 2% !important;
    }

    .logo {
        max-height: 70px;
        max-width: 100px;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: 0.5;
        transition: all .3s;
        &:hover {
            opacity: 1;
            filter: grayscale(0%);
            transform: scale(1.05);
        }
        @include responsive(mobile) {
            padding: 15px;
        }
    }
}