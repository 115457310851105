@import '../../assets/scss/all.scss';

.About {
    background-color: $primary;
    padding-top: 15px;
    width: 100%;
    z-index: 2;

    &_row {
        align-items: center;
        display: flex;
        min-height: 70vh;
        padding: 0 10%;
    }

    &_content {
        display: flex;
        justify-content: center;
        
        &-title {
            color: $third;
        }
    
        &-short {
            color: $third;
        }
    }

    &_boxes {
       display: flex;
       justify-content: space-between;
       flex-direction: row;
       flex-wrap: wrap;
       flex-wrap: 8px;
       margin: 0 0 35px;
    }

    &_box {
        align-items: center;
        background-color: $secondary;
        border-radius: 8px;
        display: flex;
        line-height: 25px;
        justify-content: center;
        flex-direction: column;
        height: 150px;
        width: 150px;
        b {
            color: white;
            margin-top: 15px;
            font-size: 17px;
        }

        small {
            color: $third;
        }

        svg {
            font-size: 50px;
        }
    }
    
    &_resume {
        margin-top: 5%;
    }

    &_img {
        border-radius: 20px;
        box-shadow: inset 2.43px 4.14px 6.57px -0.82px rgb(0 0 0 / 15%), inset -3.64px -6.21px 6.57px -0.82px rgb(0 0 0 / 30%), 7.29px 12.42px 4.93px -2.46px rgb(0 0 0 / 60%) !important;
        display: flex;
        justify-content: center;
        width: 70%;
        @include responsive(mobile) {   
            margin: 0 auto 30px;
            width: 90%;
        }
    }
}