@import 'mixins.scss';
body, html {
    background-color: $primary-2;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

.line-divider {
    background-color: $secondary;
    border: 2px solid $secondary;
    margin: 10px 0;
    width: 40px;
}

.after-banner {
    background-color: $primary;
    display: block;
    position: relative;
    z-index: 3;
}

.bgBlack {
    background-color: $primary;
}

.max-container-size {
    margin: 5% auto 0;
    max-width: 1366px;
}

.footer {
    position: relative;
    display: flex;
    height: 100px;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: row;
    padding: 20px 0;

    @include responsive(mobile) {  
        padding: 30px 0 20px;
    }

    &_developed {
        svg {
            position: relative;
            top: 7px;
            path {
                fill: $third;
                stroke: none;
            }
        }
    }

    &_socialmedia {
        display: flex;
        justify-content: space-between;
        gap: 5px;
        top: 7px;
        position: relative;
        margin-left: 15px;
        svg {
            position: relative;
            bottom: 0;
            transition: .3s all;
            path {
                fill: $third;
                stroke: none;
            }
            &:hover {
                bottom:4px;
            }
        }
    }
}

.bgBtn {
    background-color: $secondary;
}

.btn {
    background-color: $secondary;
    text-decoration: none;
    color: $third;
    padding: 10px 36px;
    z-index: 10;
}

.claymorphism-btn {
    border-radius: 10px!important;
    box-shadow: inset 6.18px 10.52px 16.70px -2.09px rgba(0, 0, 0, 0.15),
    inset -9.26px -15.78px 16.70px -2.09px rgba(0, 0, 0, 0.3),
    13px 20px 12.52px -6.26px rgb(0 0 0 / 60%)!important;
    
    svg {
        font-size: 1rem;
        margin-right: 5px;

        path {
            fill: $third;
        }
    }
    
    &:hover {
        box-shadow: inset 2.43px 4.14px 6.57px -0.82px rgba(0, 0, 0, 0.15),
        inset -3.64px -6.21px 6.57px -0.82px rgba(0, 0, 0, 0.3),
        7.29px 12.42px 4.93px -2.46px rgba(0, 0, 0, 0.6)!important;
    }
}

.top-waves {
    position: absolute;
    top: -95px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;

    .waves {
        position:relative;
        width: 100%;
        height:15vh;
        margin-bottom:-7px;
        min-height:100px;
        max-height:150px;

        .wave-path {
            stroke: none;
            fill: $primary;
        }
    }

    .parallax > use {
        animation: endless-waves 25s cubic-bezier(.55,.5,.45,.5) infinite;
    }
    .parallax > use:nth-child(1) {
        animation-delay: -2s;
        animation-duration: 7s;
    }
    .parallax > use:nth-child(2) {
        animation-delay: -3s;
        animation-duration: 10s;
        opacity: 0.8;
    }
    .parallax > use:nth-child(3) {
        animation-delay: -4s;
        animation-duration: 13s;
        opacity: 0.6;
    }
    .parallax > use:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 20s;
        opacity: 0.4;
    }

    @keyframes endless-waves {
    0% {
        transform: translate3d(-90px,0,0);
    }
    100% { 
        transform: translate3d(85px,0,0);
    }
    }
      /*Shrinking for mobile*/
    @include responsive(mobile) { 
        .waves {
          height:100px;
          min-height:100px;
          margin-bottom: 0;
        }
    }
}

.noAnchor {
    text-decoration: none;
}

.bottom-waves {
    position: absolute;
    bottom:3%;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;

    .waves {
        position:relative;
        width: 100%;
        height:15vh;
        margin-bottom:-7px;
        min-height:100px;
        max-height:150px;

        .wave-path {
            stroke: none;
            fill: $footer;
        }
    }

    .parallax > use {
        animation: endless-waves 25s cubic-bezier(.55,.5,.45,.5) infinite;
    }
    .parallax > use:nth-child(1) {
        animation-delay: -2s;
        animation-duration: 7s;
        fill: $secondary;
    }
    .parallax > use:nth-child(2) {
        animation-delay: -3s;
        animation-duration: 10s;
        opacity: 0.8;
    }
    .parallax > use:nth-child(3) {
        animation-delay: -4s;
        animation-duration: 13s;
        opacity: 0.6;
    }
    .parallax > use:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 20s;
        opacity: 0.4;
    }

    @keyframes endless-waves {
    0% {
        transform: translate3d(-90px,0,0);
    }
    100% { 
        transform: translate3d(85px,0,0);
    }
    }
      /*Shrinking for mobile*/
    @media (max-width: 768px) {
        .waves {
          height:100px;
          min-height:100px;
        }
    }
}



.hands {
    z-index: 100;

    @include responsive(mobile) { 
        display: flex;
    }

    &_right_top {
        animation: saying-hi 10s ease-in-out 10s infinite alternate;
        position: absolute;
        right: -220px;
        top: 100px;
        transform-origin: center bottom;
        transform: rotate(300deg);
        transition: all .3s;
        .no-hover {
            transition: all .3s;
            display: block;
            position: relative;
            bottom: 0;
        }
        .hover {
            transition: all .3s;
            display: none;
            position: relative;
            bottom: -240px;
            max-width: 270px;

            @include responsive(mobile) { 
                max-width: 130px;
            }
        }
        .hi {
            transition: all .3s;
            display: none;
            position: relative;
            bottom: -240px;
            max-width: 270px;

            @include responsive(mobile) { 
                max-width: 130px;
            }
        }
        &:hover {
            .no-hover {
                display: none;
                bottom: -300px;
            }
            .hover {
                display: block;
                transform: rotate(262deg);
                bottom: -20px;
                right: 40px;
                @include responsive(mobile) { 
                    
                }
            }
        }

        @include responsive(mobile) { 
            top: 60px;
            right: -115px;
        }
    }

    &_left_top {
        position: absolute;
        left: -45px;
        top: 0;
    }
    img {
        max-width: 300px;

        @include responsive(mobile) { 
            max-width: 150px;
        }
    }
}
