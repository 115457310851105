@import '../../assets/scss/all.scss';

.highlight-letter {
    position: relative;
    margin: 0 auto;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-left: 10%;

    h3 {
        font-size: 100px;
        color: white;
        font-weight: 600;
        @include responsive(mobile) {
            font-size: 80px;
        }
        svg {            
            font-size: 100px;
            position: relative;
            top: 20px;
            transition: .3s all;
            left: 0;

            @include responsive(mobile) {
                bottom: 0;
                transform: rotate(90deg);
            }
            path {
                stroke: $secondary;
                fill: $secondary;
            }
        }
        &:hover {
            svg {
                left: 10px;
                @include responsive(mobile) {  
                    left: 0;
                    bottom: -10px;
                }
            }
        }
    }
}

.contact {
    padding-bottom: 5%;
}

.formContact {
    margin-top: 10%;
    position: relative;
    z-index: 10;

    @include responsive(mobile) {  
        padding: 5.5%;
    }
    label {
        color: $third;
        display: block;
        margin: 5px 0;
    }
    input {
        background-color: $primary-2;
        box-shadow: inset 2px 2px 5px #0f0f0f, inset -5px -5px 10px #252424;
        border: 0;
        border-radius: 10px;
        color: $third;
        height: 40px;
        transition: all 0.2s ease-in-out;
        padding: 5px 10px;
        width: 100%;
        &:focus,
        &:active {
            box-shadow: inset 1px 1px 2px #0f0f0f, inset -1px -1px 2px #252424;
            outline: none;
        }
    }
    textarea {
        background-color: $primary-2;
        box-shadow: inset 2px 2px 5px #0f0f0f, inset -5px -5px 10px #252424;
        border: 0;
        border-radius: 12px;
        color: $third;
        min-height: 100px;
        resize: none;
        padding: 5px 10px;
        width: 100%;
        &:focus,
        &:active {
            box-shadow: inset 1px 1px 2px #0f0f0f, inset -1px -1px 2px #252424;
            outline: none;
        }
    }
    .error-msg {
       color: $red;
       font-size: 14px;
       margin: 2px 5px;
    }
    .Btn-send {
        background-color: $secondary;
        path {
            fill: $third;
            stroke: none;
        }
        &:hover {
            background-color: $red-2;
            box-shadow: 0px 0px 15px -3px $red-2;

        }
        &:disabled {
            color: $third;
            opacity: 0.3;
        }

        &.m-error {
            background-color: $red;
        }
    }
}

.box-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin-left: 8%;

    &.m-no_form {
        margin-left: 0;
        margin-top: 10%;
    }

    @include responsive(mobile) {
        justify-content: center;
        margin-left: 0;
        &.m-no_form {
            padding-bottom: 5%;
        }
    }

    &_mini {
        border-radius: 10px;
        display: flex;
        margin: 0 10px;
        height: 120px;
        width: 120px;
        transition: .3s all;
        position: relative;

        @include responsive(mobile) {
            width: 100px;
            height: 100px;
        }

        &_front {
            box-shadow: inset 5.00px 8.66px 13.66px -1.71px rgba(0, 0, 0, 0.15),
            inset -7.50px -12.99px 13.66px -1.71px rgba(0, 0, 0, 0.3),
            15.00px 25.98px 10.25px -5.12px rgba(0, 0, 0, 0.6);
            border-radius: 10px;
            background-color: $secondary;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            flex-direction: column;
            height: 120px;
            width: 120px;
            transition: .3s all;
            position: relative;
            z-index: 2;

            @include responsive(mobile) {
                width: 100px;
                height: 100px;
            }
        }

        &_back {
            align-items: flex-end;
            background-color: $red;
            border-radius: 10px;
            display: flex;
            height: 100%;
            justify-content: center;
            position: absolute;
            bottom: 0;
            width: 100%;
            z-index: 1;
            a {
                font-size: 12px;
                color: $third;
                opacity: 1;
                height: auto;
                width: auto;
                text-decoration: none;
                transition: .1s all;
                font-weight: 600;
                text-align: center;
                padding-bottom: 3px;
                @include responsive(mobile) {
                    font-size: 9.5px;
                }
            }

            &:hover {
                .box-info_mini_back {
                    bottom: -5px;
                }
            }
        }
        .iconic {
            font-size: 60px;
            transition: .3s all;

            path {
                fill: $third;
                stroke: none;
            }
        }

        span {
            color: $third;
            opacity: 0;
            height: 0;
            transition: .5s all;
            display: none;
            font-size: 0;
            font-weight: 600;
        }

        &:hover {
            .box-info_mini_front {
                box-shadow: inset 2.60px 4.50px 7.10px -0.89px rgba(0, 0, 0, 0.15),
                inset -3.90px -6.75px 7.10px -0.89px rgba(0, 0, 0, 0.3),
                9px 9px 5.33px -2.66px rgba(0, 0, 0, 0.2);
                height: 100px;

                @include responsive(mobile) {
                    height: 80px;
                }
            }
            .iconic {
                font-size: 40px;
                margin-bottom: 15px;
            }
            span {
                font-size: 13px;
                opacity: 1;
                height: auto;
                display: block;
                width: auto;
            }
        }
    }
}