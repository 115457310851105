@import url('https://fonts.googleapis.com/css?family=Lato');
@import '../../assets/scss/all.scss';

body {
  font-family: 'Lato', sans-serif;
}

.menu {
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  display: inline-block;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;

  &-option {
    color: rgba(255, 255, 255, 0.8);;
    transition: color 0.5s ease;
    text-decoration: none;
  }

  &:hover {
    pointer-events: all;
  }

  @include responsive(mobile) {    
    display: none;
  }
}

.label {
  display: inline-block;
  cursor: pointer;
  pointer-events: all;
}

.spacer {
  display: inline-block;
  width: 50px;
  margin-left: 5px;
  margin-right: 35px;
  vertical-align: middle;
  cursor: pointer;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    border-bottom: 1px solid #ffffff;
    height: 1px;
    width: 0%;
    transition: width 0.25s ease;
    transition-delay: 0.7s;
  }
}

.item {
  position: relative;
  display: inline-block;
  margin-right: 30px;
  top: 10px;
  opacity: 0;
  transition: opacity 0.5s ease, top 0.5s ease;
  transition-delay: 0;

  &:hover {
    .menu-option {
        color: #13d7e6;
    }
  }
}

.menu:hover {
  .spacer:before {
    width: 100%;
    transition-delay: 0s;
  }

  .item {
    opacity: 1;
    top: 0px;
  }
}

.item {
  &:nth-child(1) {
    transition-delay: 0.45s;
  }

  &:nth-child(2) {
    transition-delay: 0.4s;
  }

  &:nth-child(3) {
    transition-delay: 0.35s;
  }

  &:nth-child(4) {
    transition-delay: 0.3s;
  }

  &:nth-child(5) {
    transition-delay: 0.25s;
  }
}

.menu:hover .item {
  &:nth-child(1) {
    transition-delay: 0.25s;
  }

  &:nth-child(2) {
    transition-delay: 0.3s;
  }

  &:nth-child(3) {
    transition-delay: 0.35s;
  }

  &:nth-child(4) {
    transition-delay: 0.4s;
  }

  &:nth-child(5) {
    transition-delay: 0.45s;
  }
}

//menu icon animation
svg {
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 1.5;
cursor:pointer;
  }

  .fixedIcon {
    path {
      fill: $third;
      stroke: none;
    }
  }

  svg + svg {
    margin-left: 1.5rem;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Segoe UI, sans-serif;
  }

  .menuIcon {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: end;
    position: relative;
    top: 22px;
    svg {
      path {
        fill: none;
        stroke: $third;
        stroke-width: 1px;
      }
    }
  }

  #top,
  #bottom {
    stroke-dasharray: 30, 75.39;
    transition: all 0.6s cubic-bezier(0.6, 0.33, 0.67, 1.29);
  }

  svg.active #top,
  svg.active #bottom {
    stroke-dasharray: 75.39;
    stroke-dashoffset: -60;
  }

  svg:nth-child(2) {
    transform: rotate(0deg);
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  svg:nth-child(2) path {
    transition: all 0.4s ease-in-out 0.6s;
  }

  svg:nth-child(2).active {
    transform: rotate(180deg);
  }

  .rocket #top {
    stroke-dasharray: 30, 88;
  }
// end menu icon animation


//menu fixed and mobile

.menuFixed {
  bottom: 7%;
  background: rgba( 34, 40, 49, 0.3 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 8px );
  -webkit-backdrop-filter: blur( 8px );
  border-radius: 40px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10;
  margin: 0 auto;
  width: fit-content;
  max-width: 300px;
  padding: 0 20px;
  transition: .4s all;
  opacity: 1;
  visibility: visible;

  &.hideMenu {
    opacity: 0;
    visibility: hidden;
    bottom: 0;
  }

  .itemMenu {
    text-decoration: none;
    color: $third;
    .itemBtn {
      position: relative;
      bottom: 0;
      transition: .3s all;
      &:hover {
        bottom: 3px;
      }
    }
  }
}