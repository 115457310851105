// best seen at 1500px or less

/************* WAVES *******************/
// animation to this format:
// <div class="ocean">
//   <div class="wave"></div>
//   <div class="wave"></div>
// </div>

.ocean { 
  height: 5%;
  width:100%;
  position:absolute;
  bottom:0;
  left:0;
  background: #015871;
}

.wave {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x; 
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 198px;
  animation: wave 10s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -105px;
  animation: wave 8s cubic-bezier( 0.6, 0.5, 0.63, 0.53) -.25s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0,-25px,0);
  }
  50% {
    transform: translate3d(0,5px,0);
  }
}



// background like borrow


.borrow-bg {
    position: fixed;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    width: 200%;
    height: 200vh;
    background: transparent url('http://assets.iceable.com/img/noise-transparent.png') repeat 0 0;
    background-repeat: repeat;
    animation: borrow-animation .2s infinite;
    opacity: .9;
    visibility: visible;
  }
  

@keyframes borrow-animation {
    0% { transform: translate(0,0) }
    10% { transform: translate(-5%,-5%) }
    20% { transform: translate(-10%,5%) }
    30% { transform: translate(5%,-10%) }
    40% { transform: translate(-5%,15%) }
    50% { transform: translate(-10%,5%) }
    60% { transform: translate(15%,0) }
    70% { transform: translate(0,10%) }
    80% { transform: translate(-15%,0) }
    90% { transform: translate(10%,5%) }
    100% { transform: translate(5%,0) }
}


$size: 130px;

@include responsive(mobile) { 
  @keyframes xFloatingMobile {
    100% {
      transform: translateX( 100vw ) rotateZ(40deg);
    }
  }
  
}

@keyframes xFloating {
  100% {
    transform: translateX( calc(100vw - #{$size}) ) rotateZ(80deg);
  }
}

@keyframes yFloating {
  100% {
    transform: translateY( calc(100vh - #{$size}) ) rotateZ(80deg);
  }
}


@keyframes saying-hi {
  0%  { transform: rotate(300deg) }
  10% { transform: rotate(300deg) }
  20% { transform: rotate(280deg) }
  30% { transform: rotate(320deg) }
  40% { transform: rotate(280deg) }
  50% { transform: rotate(320deg) }
  60% { transform: rotate(280deg) }
  70% { transform: rotate(320deg) }
  80% { transform: rotate(280deg) }
  90% { transform: rotate(300deg) }
  100% { transform: rotate(300deg) }
}
